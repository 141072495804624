import { Injectable, inject } from '@angular/core';
import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import { CreatePlanModel, GetAllPlansModel, GetPlanByIdModel, UpdatePlanModel } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly pathPlans = `${this.baseApi}plans`;

  public getAllPlans(
    query: GetAllPlansModel.Request | null
  ): Observable<GetAllPlansModel.Response> {
    return this.httpService.get(
      this.pathPlans,
      query,
      'Error al consultar el listado de planes.'
    );
  }

  public createPlans(
    body: CreatePlanModel.Request
  ): Observable<CreatePlanModel.Response> {
    return this.httpService.post(
      this.pathPlans,
      body,
      'Error al crear el plan.'
    );
  }

  public getPlanById(
    planId:number | string
  ): Observable<GetPlanByIdModel.Response> {
    const url = `${this.pathPlans}/${planId}`;
    return this.httpService.get(
      url,
      null,
      'Error al consultar la información del plan.'
    );
  }

  public updatePlan(
    body:UpdatePlanModel.Request,
    planId:number | string
  ):Observable<UpdatePlanModel.Response>{
    const url = `${this.pathPlans}/${planId}`;
    return this.httpService.patch(
      url,
      body,
      'Error al actualizar los datos del plan'
    )
  }
}
